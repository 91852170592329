import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { closeConfirmRetryPaymentModal, handleRetryPayment } from './subscriptionSlice';

export function ConfirmRetryPayment(props) {
    const dispatch = useDispatch();
    const { selectedId } = props;

    const submit = async () => {
        dispatch(handleRetryPayment());
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeConfirmRetryPaymentModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Retry payment</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Are you sure you want to retry payment for this subscription (${selectedId})?`}
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeConfirmRetryPaymentModal())} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => submit()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}