import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { SubscriptionChargeStatus } from '../../../app/constants';
import { getParkitSubscriptions } from '../../../api/parkitSubscriptionAPI';

export const subscriptionSlice = createSlice({
  name: 'parkitSubscription',
  initialState: {
    subscriptions: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
});

export const { setSelectedId, setPage, setRowsPerPage, setPagination, setSubscriptions, setSuccessMessage, setErrorMessage } = subscriptionSlice.actions;

export const selectState = state => state.parkitSubscription;

export const handleGeSubscriptions = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getParkitSubscriptions(state.parkitSubscription.page, state.parkitSubscription.rowsPerPage, `${SubscriptionChargeStatus.PENDING},${SubscriptionChargeStatus.PAID}`);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setSubscriptions(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setSubscriptions([]));
  } finally {
    dispatch(hideLoading());
  }
}

export default subscriptionSlice.reducer;