import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Paper, Checkbox, TablePagination, TableFooter, InputBase, IconButton, debounce } from "@mui/material";
import Moment from 'moment';
import styles from './TrackingSheet.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import EditRentingModal from './EditRentingModal';
import EditListingModal from './EditListingModal';
import AllocateRentingModal from './AllocateRentingModal';
import DeallocateRentingModal from './DeallocateRentingModal';
import ActivateRentingModal from './ActivateRentingModal';
import TerminateRentingModal from './TerminateRentingModal';
import CreateAppointmentModal from './CreateAppointmentModal';
import SwapParkingModal from './SwapParkingModal';
import FreezeRentingModal from './FreezeRentingModal';
import UnfreezeRentingModal from './UnfreezeRentingModal';

import TablePaginationActions from './TablePaginationActions';
import { TableToolbar } from './TableToolbar';
import { showFilterModal, handleGetRentings, selectState, setSelectedId, setSearchValue, setPage, setRowsPerPage, setSort, setSortBy, setErrorMessage, setSuccessMessage, setSelectedRenting } from './trackingSheetSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import { NearbyType } from '../../../app/constants';
import { centToDollar } from '../../../app/utils';
import FilterRentingModal from './FilterRentingModal';

export function TrackingSheet() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetRentings());
  }, [state.sort, state.sortBy, state.selectedFilter, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetRentings());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetRentings());
  };

  const handleRequestSort = (property) => {
    const isDescending = state.sortBy === property && state.sort === 'desc';
    dispatch(setSort(isDescending ? 'asc' : 'desc'));
    dispatch(setSortBy(property));
  };

  const onSearchValueChange = (e) => {
    dispatch(setPage(0));
    dispatch(setSearchValue(e.target.value));
    dispatch(handleGetRentings());
  }

  const debouncedOnSearchValueChange = debounce(onSearchValueChange, 1000);

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => dispatch(showFilterModal(true))}>
            <FilterListIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                numSelected={state.selectedId === null ? 0 : 1}
                hasListing={state.selectedRenting?.listing}
                title="Tracking Sheet"
                startDate={state.rentings.filter(r => r.id === state.selectedId)[0]?.startDate}
                actionables={state.rentings.filter(r => r.id === state.selectedId)[0]?.actionables}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'startDate'}
                    direction={state.sortBy === 'startDate' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('startDate')}
                  >
                    Start Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Notification</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Requested Parking Area</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Available Bay</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Bay</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Sell Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Buy Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Remark</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Nearby Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'status'}
                    direction={state.sortBy === 'status' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('status')}
                  >
                    Driver Status
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Operation Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Delivery Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Events</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Last Drop Off Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Access Card Received</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vehicle</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'additionalInformation.bookingDate'}
                    direction={state.sortBy === 'additionalInformation.bookingDate' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('additionalInformation.bookingDate')}
                  >
                    Admin Fees Payment Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Deposit Payment Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Renting Id</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                  <TableSortLabel
                    active={state.sortBy === 'createdAt'}
                    direction={state.sortBy === 'createdAt' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                  <TableSortLabel
                    active={state.sortBy === 'updatedAt'}
                    direction={state.sortBy === 'updatedAt' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('updatedAt')}
                  >
                    Updated At
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.rentings.length > 0 ? (
                  state.rentings.map((renting) => (
                    <TableRow
                      key={renting.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === renting.id ? null : renting.id)); dispatch(setSelectedRenting(state.selectedId === renting.id ? null : renting)); }}
                      role="checkbox"
                      aria-checked={state.selectedId === renting.id ? true : false}
                      selected={state.selectedId === renting.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === renting.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.startDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${renting.customer.user.firstName} ${renting.customer.user.lastName}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.customer.user.userName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.customer.user.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.isSuppressNotification ? "Disabled" : "Enabled"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.requestedParkingArea?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.requestedParkingArea?.additionalInformation?.totalVacant}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.listing ? `${renting.listing?.parkingBay.bayNumber} - ${renting.listing?.parkingBay.address}` : renting.listingId}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(renting.sellPrices?.monthly)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(renting.listing?.buyPrices?.monthly)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.remark}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.nearbyType ?? NearbyType.NONE}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.operationStatus}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.deliveryAddress}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.appointments}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.lastDropOffStatus}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.accessCardReceived}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.vehicle ? `${renting.vehicle.registrationNumber} (${renting.vehicle.color} ${renting.vehicle.model})` : "N/A"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.bookingDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.depositPaymentDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.id}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{Moment(renting.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{Moment(renting.updatedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={25} align="center">
                      <p>No driver enquiries.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.editRentingModalOpen && (<EditRentingModal />)}
      {state.editListingModalOpen && (<EditListingModal />)}
      {state.allocateModalOpen && (<AllocateRentingModal />)}
      {state.deallocateModalOpen && (<DeallocateRentingModal />)}
      {state.activateModalOpen && (<ActivateRentingModal />)}
      {state.terminateModalOpen && <TerminateRentingModal />}
      {state.createAppointmentModalOpen && <CreateAppointmentModal />}
      {state.filterModalOpen && <FilterRentingModal />}
      {state.swapParkingModalOpen && <SwapParkingModal />}
      {state.freezeRentingModalOpen && <FreezeRentingModal />}
      {state.unfreezeRentingModalOpen && <UnfreezeRentingModal />}
    </div>
  );
}