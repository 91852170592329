import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TablePagination, TableFooter } from "@mui/material";
import styles from './FailedSubscription.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetFailedSubscriptions, selectState, setSuccessMessage, setErrorMessage, setFailedSubscriptionPage, setFailedSubscriptionRowsPerPage, setSelectedFailedSubscriptionId, openConfirmRetryPaymentModal } from './failedSubscriptionSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import { centToDollar, formatCurrency } from '../../../app/utils';
import { ConfirmRetryPayment } from './ConfirmRetryPayment';

export function FailedSubscription() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetFailedSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangeFailedSubscriptionPage = (event, newPage) => {
    dispatch(setFailedSubscriptionPage(newPage));
    dispatch(handleGetFailedSubscriptions());
  };

  const handleChangeFailedSubscriptionRowsPerPage = (event) => {
    dispatch(setFailedSubscriptionRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setFailedSubscriptionPage(0));
    dispatch(handleGetFailedSubscriptions());
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                title="Failed Subscriptions"
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Username</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Phone</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Area</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Sell Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Charge Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Retry Attempts</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Subscription Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Buy Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Next Billing Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Action</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.failedSubscriptions.length > 0 ? (
                  state.failedSubscriptions.map((subscription) => (
                    <TableRow key={subscription.id}>
                      <TableCell align="left" className="min-w-[120px] break-all"><Link className="text-blue-500" to={`/parkit/wallets/${subscription.walletId}/subscriptions/${subscription.id}`} target='_blank'>{subscription.id}</Link></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.firstName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.lastName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}><Link className="text-blue-500" to={`/users/${subscription.renter?.user?.id}/subscriptions`} target='_blank'>{subscription.renter?.user?.userName}</Link></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.renter?.user?.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.parkingArea?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.parkingBay?.bayNumber}</TableCell>
                      <TableCell align="left" className="min-w-[120px] break-words">{subscription.sellPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.sellPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.chargeStatus}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.numberOfRetries || 0}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.type}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${subscription.owner?.user?.firstName || 'N/A'} ${subscription.owner?.user?.lastName || ''}`}</TableCell>
                      <TableCell align="left" className="min-w-[120px] break-words">{subscription.buyPrice ? `${formatCurrency(subscription.currency)} ${centToDollar(subscription.buyPrice[String(subscription.interval).toLowerCase()])}` : 'N/A'}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{subscription.nextBillingDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>
                        <button 
                          className="text-blue-500 text-left" 
                          onClick={() => {
                            dispatch(setSelectedFailedSubscriptionId(subscription.id));
                            dispatch(openConfirmRetryPaymentModal());
                          }}
                        >
                          Retry payment
                        </button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={14} align="center">
                      <p>No failed subscriptions.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={6}
                  count={state.failedSubscriptionPagination?.totalElements || 0}
                  rowsPerPage={state.failedSubscriptionRowsPerPage}
                  page={state.failedSubscriptionPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangeFailedSubscriptionPage}
                  onRowsPerPageChange={handleChangeFailedSubscriptionRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {
        state.confirmRetryPaymentModalOpen  &&
        <ConfirmRetryPayment selectedId={state.selectedFailedSubscriptionId} />
      }
    </div>
  );
}