import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Paper, Grid, TextField, FormLabel, Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, TableFooter, TablePagination, debounce, InputBase, IconButton, LinearProgress } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from './Masterlist.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
import SearchIcon from '@mui/icons-material/Search';

import { ListingStatus, SearchType } from "../../../app/constants";
import { getListings, getListingsWithRentingTerminating } from "../../../api/listingsAPI";
import TablePaginationActions from './TablePaginationActions';
import { SwapTableToolbar as TableToolbar } from './SwapTableToolbar';
import { handleSwapParking, selectState, showSwapParkingModal, setErrorMessage } from './masterlistSlice';
import useToasts from "../../../hooks/useToasts";
import { centToDollar, dollarToCent } from "../../../app/utils";

const SwapParkingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { toastError } = useToasts();
  const [listingLoading, setListingLoading] = useState(false)

  const renting = state.selectedRenting;
  const [selectedId, setSelectedId] = useState(null);
  const [monthlySellPrice, setMonthlySellPrice] = useState(null);
  const [listings, setListings] = useState([]);
  const [searchValue, setSearchValue]= useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    const handleGetListings = async () => {
      setListingLoading(true);
      try {
        const [vacantListings, rentingTerminatingListings] = await Promise.all([
          getListings(page, rowsPerPage, "desc", "createdAt", `${ListingStatus.VACANT}`, searchValue, SearchType.INVENTORY, null),
          getListingsWithRentingTerminating(page, rowsPerPage, "desc", "createdAt", `${ListingStatus.OCCUPIED},${ListingStatus.ACTIVE}`, searchValue, SearchType.INVENTORY)
        ]);
    
        const availableListings = [
          ...vacantListings.data.content,
          ...rentingTerminatingListings.data.content
        ];
    
        const combinedPagination = {
            pageable: vacantListings.data.pageable,
            totalPages: Math.max(vacantListings.data.totalPages, rentingTerminatingListings.data.totalPages),
            totalElements: vacantListings.data.totalElements + rentingTerminatingListings.data.totalElements,
            last: vacantListings.data.last && rentingTerminatingListings.data.last,
            size: vacantListings.data.size,
            number: vacantListings.data.number,
            sort: vacantListings.data.sort, 
            numberOfElements: availableListings.length,
            first: vacantListings.data.first && rentingTerminatingListings.data.first,
            empty: availableListings.length === 0
        };
    
        setPagination(combinedPagination);
        setListings(availableListings);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch listings."));
      } finally {
        setListingLoading(false);
      }
    };

    handleGetListings();
  }, [page, rowsPerPage, searchValue, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const debouncedSetSearchValue = useRef(
    debounce((value) => {
      setPage(0);
      setSearchValue(value);
    }, 1000)
  ).current;

  const onSearchValueChange = (e) => {
    const cleanValue = e.target.value.replace(/\+/g, '');
    debouncedSetSearchValue(cleanValue);
  };

  const validateFields = () => {
    if (!selectedId || !monthlySellPrice) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (validateFields()) {
      try {
        const payload = {
          newListingId: selectedId,
          newSellPrices: {
            monthly: dollarToCent(monthlySellPrice),
          }
        }
        dispatch(handleSwapParking(payload));
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="swap-parking-masterlist-data-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[1200px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showSwapParkingModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Swap Parking Bays</p>
          <Grid container columnSpacing={4}>
            <Grid item xs={12}>
              <FormLabel component="legend">{renting.customer.user.firstName} - {renting.listing.parkingBay.bayNumber}, {renting.listing.parkingBay.address}</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="monthly-sell-price"
                label="Monthly Sell Price"
                onChange={(e) => setMonthlySellPrice(e.target.value)}
                value={monthlySellPrice ?? ""}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
            <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
              <Paper
                component="form"
                sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
                  onChange={onSearchValueChange}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
                <div className="h-[64px] absolute left-0 top-0 right-0">
                  <TableToolbar
                    numSelected={selectedId === null ? 0 : 1}
                  />
                </div>
              </div>
              {listingLoading && <LinearProgress />}
              <Table size="small">
                <StickyTableHead>
                  <TableRow>
                    <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                    <TableCell align="left" className={styles.tableHeader250}>Address</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Parking Area</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                    <TableCell align="left" className={styles.tableHeader}>Buy Price</TableCell>
                  </TableRow>
                </StickyTableHead>
                <TableBody>
                  {
                    listings.length > 0 ? (
                      listings.map((listing) => (
                        <TableRow
                          key={listing.id}
                          hover
                          onClick={() => { setSelectedId(selectedId === listing.id ? null : listing.id); setMonthlySellPrice(selectedId === listing.id ? null : centToDollar(listing.buyPrices?.monthly)); }}
                          role="checkbox"
                          aria-checked={selectedId === listing.id ? true : false}
                          selected={selectedId === listing.id ? true : false}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={selectedId === listing.id ? true : false}
                            />
                          </TableCell>
                            <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.bayNumber}</TableCell>
                            <TableCell align="left" className={styles.tableCell250}>{listing.parkingBay?.address}</TableCell>
                            <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.unitNumber}</TableCell>
                            <TableCell align="left" className={styles.tableCell}>{listing.parkingArea?.name}</TableCell>
                            <TableCell align="left" className={styles.tableCell}>{listing.status !== ListingStatus.VACANT ? `${listing.status} (Driver Terminating)` : listing.status}</TableCell>
                            <TableCell align="left" className={styles.tableCell}>{centToDollar(listing.buyPrices?.monthly)}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={27} align="center">
                          <p>No Available Listings</p>
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={4}
                      count={pagination ? pagination.totalElements ? pagination.totalElements : 0 : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button onClick={submit} className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md">Swap</button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

export default SwapParkingModal;