import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Paper, Grid, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem } from "@mui/material";
import { Close } from "@mui/icons-material";

import { handleUpdateListing, selectState, showEditModal } from './listingSlice';
import { centToDollar, dollarToCent } from '../../../app/utils';
import { BusinessType } from '../../../app/constants';


const EditListingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const listing = state.selectedListing;
  const [bayNumber, setBayNumber] = useState(listing.parkingBay?.bayNumber);
  const [unitNumber, setUnitNumber] = useState(listing.parkingBay?.unitNumber);
  const [monthlyPrice, setMonthlyPrice] = useState(centToDollar(listing.buyPrices?.monthly));
  const [remark, setRemark] = useState(listing.additionalInformation?.remark);
  const [businessType, setBusinessType] = useState(listing.additionalInformation?.businessType ?? BusinessType.BUSINESS_TO_CONSUMER);
  const [handlingFees, setHandlingFees] = useState(listing.additionalInformation?.handlingFees ? centToDollar(listing.additionalInformation?.handlingFees) : null);
  const [buildingType, setBuildingType] = useState(listing.parkingBay?.type);
  const [bayFeature, setBayFeature] = useState(listing.additionalInformation?.bayFeature);
  const [parkingType, setParkingType] = useState(listing.additionalInformation?.parkingType);
  const [dedicatedSpace, setDedicatedSpace] = useState(listing.parkingBay?.dedicatedSpace);
  const [rfidNumber, setRfidNumber] = useState(listing.parkingBay?.rfidNumber);
  const [cardSerialNumber, setCardSerialNumber] = useState(listing.parkingBay?.cardSerialNumber);

  const submit = async () => {
    try {
      const payload = {
        buyPrices: {
          ...listing.buyPrices,
          monthly: dollarToCent(monthlyPrice)
        },
        parkingBay: {
          ...listing.parkingBay,
          bayNumber,
          unitNumber,
          type: buildingType,
          dedicatedSpace,
          rfidNumber,
          cardSerialNumber
        },
        additionalInformation: {
          ...listing.additionalInformation,
          remark,
          ...(handlingFees ? { handlingFees: dollarToCent(handlingFees) } : {}),
          businessType,
          bayFeature,
          parkingType
        }
      }
      dispatch(handleUpdateListing(payload));
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="edit-listing-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showEditModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Listing</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="bayNumber"
                label="Bay Number"
                onChange={(e) => setBayNumber(e.target.value)}
                value={bayNumber}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="unitNumber"
                label="Unit Number"
                onChange={(e) => setUnitNumber(e.target.value)}
                value={unitNumber}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="building-type">Building Type</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setBuildingType(e.target.value)}
                  value={buildingType}
                  input={<OutlinedInput name="buildingType" id="building-type" label="Building Type" />}
                >
                  <MenuItem key="LANDED" value="LANDED">Landed</MenuItem>
                  <MenuItem key="APARTMENT" value="APARTMENT">Apartment</MenuItem>
                  <MenuItem key="COMMERCIAL" value="COMMERCIAL">Commercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="dedicated-space">Dedicated Space</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setDedicatedSpace(e.target.value)}
                  value={dedicatedSpace}
                  input={<OutlinedInput name="dedicatedSpace" id="dedicated-space" label="Dedicated Space" />}
                >
                  <MenuItem key="RESERVED" value="true">RESERVED</MenuItem>
                  <MenuItem key="FLOATING" value="false">FLOATING</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="business-type">Business Type</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setBusinessType(e.target.value)}
                  value={businessType}
                  input={<OutlinedInput name="businessType" id="business-type" label="Business Type" />}
                >
                  <MenuItem key={BusinessType.BUSINESS_TO_BUSINESS} value={BusinessType.BUSINESS_TO_BUSINESS}>B2B</MenuItem>
                  <MenuItem key={BusinessType.BUSINESS_TO_CONSUMER} value={BusinessType.BUSINESS_TO_CONSUMER}>B2C</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="buyPricePerMonth"
                label="Price Per Month"
                onChange={(e) => setMonthlyPrice(e.target.value)}
                value={monthlyPrice}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="handlingFees"
                label="Handling Fees (%)"
                onChange={(e) => setHandlingFees(e.target.value)}
                value={handlingFees}
                margin="normal"
                variant="outlined"
                type="number"
                InputProps={{
                    onWheel: (e) => e.target.blur()
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="rfidNumber"
                label="RFID Serial Number"
                onChange={(e) => setRfidNumber(e.target.value)}
                value={rfidNumber}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="cardSerialNumber"
                label="Card Serial Number"
                onChange={(e) => setCardSerialNumber(e.target.value)}
                value={cardSerialNumber}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="bay-feature">Bay Feature</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setBayFeature(e.target.value)}
                  value={bayFeature}
                  input={<OutlinedInput name="bayFeature" id="bay-feature" label="Bay Feature" />}
                  disabled
                >
                  <MenuItem key="RESIDENT" value="RESIDENT">Resident</MenuItem>
                  <MenuItem key="STAFF" value="STAFF">Staff</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="parking-type">Parking Type</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setParkingType(e.target.value)}
                  value={parkingType}
                  input={<OutlinedInput name="parkingType" id="parking-type" label="Parking Type" />}
                  disabled
                >
                  <MenuItem key="OUTDOOR_NON_COVERED" value="OUTDOOR_NON_COVERED">Outdoor, Non-covered</MenuItem>
                  <MenuItem key="OUTDOOR_COVERED" value="OUTDOOR_COVERED">Outdoor, Covered</MenuItem>
                  <MenuItem key="INDOOR" value="INDOOR">Indoor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="remark"
                label="Remarks"
                onChange={(e) => setRemark(e.target.value)}
                value={remark}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button onClick={submit} className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md">Update</button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

export default EditListingModal;