import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, CircularProgress } from "@mui/material";
import { Close } from "@mui/icons-material";
import SearchableSelect from "react-select";
import { getAllParkingAreas } from "../parkingArea/parkingAreaAPI";
import { addRenting } from '../../../api/rentingsAPI';
import useToasts from "../../../hooks/useToasts";

import {
  selectState,
  showConvertToRentingModal,
  handleConvertToRentingEnquiry,
  setErrorMessage
} from "./buildingEnquiriesSlice";

const ConvertToRentingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { toastError } = useToasts();
  const [parkingAreaLoading, setParkingAreaLoading] = useState(false);

  const enquiry = state.selectedEnquiry;
  const [parkingAreas, setParkingAreas] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [areaName, setAreaName] = useState("");

  useEffect(() => {
    const handleGetParkingAreas = async () => {
      setParkingAreaLoading(true);
      try {
        const response = await getAllParkingAreas(0, 999999, "desc", "createdAt");
        const { content } = response.data;
        setParkingAreas(content);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch parking areas."));
      } finally {
        setParkingAreaLoading(false);
      }
    };
    handleGetParkingAreas();
  }, [dispatch]);

  const validateFields = () => {
    if (!areaId) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (validateFields()) {
      try {
        const payload = {
          customer: {
            id: enquiry.customer.id
          },
          requestedParkingAreaId: areaId,
          startDate: enquiry.startDate,
        };

        const res = await addRenting(payload);
        if(res.error) throw res.error;

        dispatch(handleConvertToRentingEnquiry(state.selectedId));
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="convert-to-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showConvertToRentingModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Convert Enquiry to Renting</p>
          <Grid container spacing={2}>
            <Grid item xs={12} zIndex={999}>
              <p className="my-[5px] text-[#929292] text-[14px]">
                Parking Area
              </p>
              {parkingAreaLoading ? (
                <CircularProgress />
              ) : (
                <SearchableSelect
                  placeholder="Parking Area"
                  value={{ value: areaId, label: areaName }}
                  onChange={(e) => {
                    setAreaId(e.value);
                    setAreaName(e.label);
                  }}
                  options={[
                    ...parkingAreas.map((area) => {
                      return {
                        label: area.name,
                        value: area.id,
                      };
                    }),
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[60px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Convert
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default ConvertToRentingModal;
